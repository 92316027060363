@import 'Variables';

.mailBlock {
    background-color: $windowColor;
    width: 280px;
    height: 100%;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    overflow: hidden;

    &__topbar {
        background-color: #3d3d3d70;
        width: 100%;
        height: 30px;
        position: relative;

        &__close {
            margin: 0.6rem 0.7rem;
        }
    }

    &__form {
        width: 95%;
        margin: 0 auto;

        &__send {
            background: transparent;
            background-image: url('../img/send-icon.svg');
            background-repeat: no-repeat;
            width: 24px;
            height: 18px;
            padding: 0;
            position: absolute;
            top: 7px;
            left: 2rem;
            appearance: none;
            margin: 0;
        }

        form {
            input {
                border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
                margin: 0 0 .5rem 0;
                height: 30px;
            }

            textarea {
                height: 200px;
                resize: none;
            }

            input,
            textarea {
                width: 100%;
                background: transparent;
                outline: none;
                border: 0;
                margin: 0 auto;
                color: $textColor;
                font-family: $secondaryFont;
                font-size: 1rem;

                &::-webkit-input-placeholder {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    color: $textColor;
                }

                &::-moz-placeholder {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    color: $textColor;
                    opacity: 1;
                }

                &::-ms-input-placeholder {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    color: $textColor;
                }

                &:-ms-input-placeholder {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    color: $textColor;
                }

                &:-moz-placeholder {
                    font-family: $secondaryFont;
                    font-size: 1rem;
                    color: $textColor;
                }
            }


        }

    }

}