@import 'Variables';

body {
  @include initialConfig;
  background-color: black;
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  input {
    margin: 0;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  a {
    color: $textColor;
  }

  p {
    font-family: $secondaryFont;
    color: $textColor;
  }

  h1 {
    font-size: $header1;
  }

  h2 {
    font-size: $header2;
  }

  h3 {
    font-size: $header3;
  }

  h4 {
    font-size: $header4;
  }

  h5 {
    font-size: $header5;
  }

  button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .button {
    @include closeBtn;
  }

  &.shut-down {
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      animation: shutDown 500ms linear;
      -webkit-animation: shutDown 500ms linear;
}
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }


  @keyframes shutDown {
    from {
      background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
      opacity: 0;
    }
    to {
      background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 0%);
      opacity: 1;
    }
  }


}


