@import 'Variables';

.randomBlock {
    @include glassShadowBlack(0.5);
    width: 250px;
    height: 300px;
    overflow: hidden;

    &__topNav {
        height: 30px;
        width: 100%;
        background-color: #1E1E1E;
        position: relative;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;

        &__pedro {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            overflow: hidden;

            &__circle {
                position: relative;
                animation: spin 6000ms linear infinite normal forwards;
                -webkit-animation: spin 6000ms linear infinite normal forwards;
                transform-origin: 50% 50%;
                width: 100%;
                height: 100%;

                &__raccoon {
                    object-fit: cover;
                    position: absolute;
                    top: 40px;
                    left: 40px;
                    animation: shake 350ms linear infinite normal forwards;
                    -webkit-animation: shake 350ms linear infinite normal forwards;
}
            }
        }

        &__copy {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 1rem;
            gap: .15rem;
        }
    }

    @keyframes spin {
        to {
            transform: rotate(-360deg);
            -webkit-transform: rotate(-360deg);
            -moz-transform: rotate(-360deg);
            -ms-transform: rotate(-360deg);
            -o-transform: rotate(-360deg);
        }
    }

    @keyframes shake {
        0%, 100% {
            transform: translate(0, 0);
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            -o-transform: translate(0, 0);
        }
        50% {
            transform: translate(-25px, -25px);
            -webkit-transform: translate(-25px, -25px);
            -moz-transform: translate(-25px, -25px);
            -ms-transform: translate(-25px, -25px);
            -o-transform: translate(-25px, -25px);
        }
    }
}