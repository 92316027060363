//variables
$iconH: 40px;
$topbarH: 25px;
$textColor: hsl(0, 0%, 100%);
$windowColor: #1E1E1E;
$btnColor: #eb5547;
$header1: 1.383rem;
$header2: 1.296rem;
$header3: 1.215rem;
$header4: 1.138rem;
$header5: 1.067rem;
$primaryFont: "San Francisco Display Medium";
$secondaryFont: "San Francisco Display Thin";
$transition: all 150ms cubic-bezier(0.465, 0.183, 0.153, 0.946);

//fonts

@font-face {
    font-family: "San Francisco Display Thin";
    font-style: normal;
    font-weight: 400;
    src: url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.eot?#iefix) format("embedded-opentype"), url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.woff2) format("woff2"), url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.woff) format("woff"), url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-thin-webfont.ttf) format("truetype")
}
@font-face {
    font-family: "San Francisco Display Medium";
    font-style: normal;
    font-weight: 400;
    src: url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.eot?#iefix) format("embedded-opentype"), url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff2) format("woff2"), url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff) format("woff"), url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.ttf) format("truetype")
}

//mixins
@mixin glassNav {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(5.5px);
}
@mixin glassTopNav {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(5.5px);
}
@mixin glassShadowBlack($opacity) {
    background: rgba(0, 0, 0, $opacity);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(5.5px);
    backdrop-filter: blur(5.5px);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

@mixin initialConfig {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $primaryFont;
    color: $textColor;
    overflow: hidden;
}

@mixin divImg($bgSize) {
    background-position: center center;
    background-size: $bgSize;
    background-repeat: no-repeat;
}

@mixin closeBtn {
    width: 10px;
    height: 10px;
    background-color: $btnColor;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    margin: .5rem .5rem;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
}

@mixin navbarAnimStart {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 150ms ease-in-out;
    -moz-transition: 150ms ease-in-out;
    -ms-transition: 150ms ease-in-out;
    -o-transition: 150ms ease-in-out;
    transition: 150ms ease-in-out;
    transform-origin: bottom center;
}

@mixin navbarAnimEnd {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: 150ms ease-in-out;
    -moz-transition: 150ms ease-in-out;
    -ms-transition: 150ms ease-in-out;
    -o-transition: 150ms ease-in-out;
    transition: 150ms ease-in-out;
    transform-origin: bottom center;
}

// Media Querys
@mixin small-screen {
    @media (max-width: 767.98px) {
        @content;
    }
}

@mixin medium-screen {
    @media (min-width: 768px) and (max-width: 1024.98px) {
        @content;
    }
}

@mixin large-screen {
    @media (min-width: 1025px) and (max-width: 1439.98px) {
        @content;
    }
}

@mixin xl-screen {
    @media (min-width: 1440px) and (max-width: 1919.98px) {
        @content;
    }
}
@mixin xxl-screen {
    @media (min-width: 1920px) {
        @content;
    }
}

