@import 'Variables';

.topbar {
    @include glassTopNav;
    width: 100%;
    height: $topbarH;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 100;

    #tag {
        font-family: "San Francisco Display Medium" !important;
    }

    .icon {
        @include divImg(contain);
        height: calc($topbarH - 5px);
        width: calc($topbarH - 5px);
    }

    &__submenu {
        width: max-content;
        height: fit-content;
        position: absolute;
        top: 25px;
        left: 8px;
        padding: .5rem;
        @include glassShadowBlack(0.4);
        transition: $transition;
            opacity: 0;
            pointer-events: none;

            &.open {
                pointer-events: auto;
                opacity: 1;
            }

        ul {
            width: 100%;


            li {
                min-width: max-content;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;

                span:not(.line) {
                    padding: .25rem .5rem;

                }

                .line {
                    width: 100%;
                    height: 1px;
                    background-color: rgba(255,255,255,0.5);
                    margin: .5rem 0;
                }

                &:hover:not(:nth-child(2)) {
                    background-color: #3a86de;
                    border-radius: 2.5px;
                    -webkit-border-radius: 2.5px;
                    -moz-border-radius: 2.5px;
                    -ms-border-radius: 2.5px;
                    -o-border-radius: 2.5px;
                }
            }
        }
    }

    &__apple {
        background-image: url('../img/pear.svg');
        margin: 0 1rem;
    }

    &__brightness {
        background-image: url('../img/brightness-icon.svg');
        margin: 0 1rem 0 auto;
    }

    #date {
        width: 5.5rem;
        margin: 0 0.5rem 0 0;
    }
    #time {
        width: 3rem;
    }

    #date,
    #time {
        border: none;
        outline: none;
        background: transparent;
        color: $textColor;
        font-family: "San Francisco Display Medium";
        font-size: inherit;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    #date:focus,
    #time:focus {
        outline: none;
    }

    input[type="text"][disabled] {
        color: $textColor !important;
    }
}