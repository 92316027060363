@import 'Variables';

.notesBlock {
    @include glassShadowBlack(0.5);
    width: 300px;
    height: 400px;
    position: relative;
    overflow: hidden;

    &__name {
        background-color: #bf9d2c;
        width: 20%;
        margin: 0 0 0 .5rem;
        padding: .5rem;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border-radius: 5px;

        p {
            font-size: .75rem;
        }

        p::before {
            content: url('../img/folder-notes-icon.svg');
            width: 15px;
            height: 13px;
            margin: 0 .25rem 0 0;
        }
    }

    &__body {
        width: 70%;
        height: calc(100% + 1px);
        background-color: #1E1E1E;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-overflow-scrolling: touch !important;
        overflow-y: auto !important;

        &__wrapper {
            padding: .5rem;
            h2 {
                margin: .75rem 0 0 0;
            }
            h3 {
                margin: .5rem 0 0 0;
            }
            p {
                font-size: smaller;
            }
            .stack {
                font-weight: 700;
                font-size: medium;
                color: #BF9D2C;
            }

        }

    }
    @include xxl-screen{
        width: 450px;
        height: 500px;
        &__name {
            width: 23%;
        }
    }
}
