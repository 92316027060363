@import 'Variables';

.navbar {
    @include glassNav;
    display: grid;
    grid-template-columns: repeat(4, calc($iconH + 15px));
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    padding: .75rem .25rem;
    width: fit-content;

    img {
        //@include divImg(contain);
        width: $iconH;
        height: $iconH;
        border-radius: 10px;
        //place-self: center;
        object-fit: contain;
    }

    .notes,
    .safari,
    .mail,
    .drawings {
        @include navbarAnimStart;
        &:hover{
            @include navbarAnimEnd;
        }
    }
    /* .notes {
        background-image: url('../img/notes-icon.svg');
    }
    .safari {
        background-image: url('../img/safari-icon.svg');
    }
    .mail {
        background-image: url('../img/mail-icon.svg');
    }
    .drawings {
        background-image: url('../img/photos-icon.svg');
    } */

    @include small-screen {
        .drawings,
        .mail,
        .safari,
        .notes {
            transform: scale(1) !important;
            -webkit-transform: scale(1) !important;
            -moz-transform: scale(1) !important;
            -ms-transform: scale(1) !important;
            -o-transform: scale(1) !important;
        }
    }
}