@import 'Variables';

.App {
  @include divImg(cover);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -webkit-animation: blurIn .5s ease-in-out 1;
  animation: blurIn .5s ease-in-out 1;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  @keyframes blurIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  ul {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    list-style: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  &__loader {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;

    svg {
      width: 150px;
      height: 150px;
    }

    .progressBar {
      width: 150px;
      position: relative;
    }

    .progress2 {
      border: 1px solid #fff;
      border-radius: 30px;
    }

    .progress-bar2 {
      height: 5px;
      border-radius: 30px;
      transition-property: width;
      -webkit-transition: 0.4s ease-in-out;
      -moz-transition: 0.4s ease-in-out;
      -ms-transition: 0.4s ease-in-out;
      -o-transition: 0.4s ease-in-out;
      transition: 0.4s ease-in-out;
    }

    .progress-moved .progress-bar2 {
      background-color: #fff;
      animation: progress 2s ease-in-out 1;
      -webkit-animation: progress 2s ease-in-out 1;
    }

    @keyframes progress {
      0% {
        width: 0%;
      }

      100% {
        width: 100%;
      }
    }

  }

  .css-selector {
    background: linear-gradient(-90deg, #dc86ed, #2bafef, #22def5, #0aee83);
    background-size: 300% 300%;
    animation: gradient 5s ease infinite;
    -webkit-animation: gradient 5s ease infinite;
    -moz-animation: gradient 5s ease infinite;
}

  @-webkit-keyframes gradient {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
  }
  @-moz-keyframes gradient {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  &__bg {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    picture,
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }


  &__brightness {
    position: absolute;
    right: 70px;
    top: 0;
  }

  &__navbar {
    position: absolute;
    bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
}

  &__screen {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    .screen {
      width: 100%;
      height: 100%;
    }

    &__notes,
    &__safari,
    &__mail,
    &__draw,
    &__random {
      position: absolute;
    }

    &__draw {
      left: 15%;
      top: 20%;
    }
    &__mail {
      left: 10%;
      top: 20%;
    }
    &__notes {
      left: 12%;
      top: 15%;
    }
    &__safari {
      left: 8%;
      top: 8%;
    }

    &__random {
      top: 5%;
      left: 15%;
    }
  }

  .clicked::after{
    content: '';
    background: rgba(255, 255, 255, 0.5);
    width: 5px;
    height: 5px;
    display: block;
    position: absolute;
    border-radius: 5px;
    transform: translate(24px, 0px);
    -webkit-transform: translate(24px, 0px);
    -moz-transform: translate(24px, 0px);
    -ms-transform: translate(24px, 0px);
    -o-transform: translate(24px, 0px);
}

  &__topbar {
    width: 100%;
  }

  @include large-screen {
    &__screen {
      &__draw {
        left: 65%;
        top: 20%;
      }

      &__mail {
        left: 10%;
        top: 30%;
      }

      &__notes {
        left: 50%;
        top: 15%;
      }

      &__safari {
        left: 28%;
        top: 18%;
      }
    }
  }
  @include xl-screen {

    &__screen {
      &__draw {
        left: 65%;
        top: 20%;
      }

      &__mail {
        left: 10%;
        top: 30%;
      }

      &__notes {
        left: 50%;
        top: 15%;
      }

      &__safari {
        left: 28%;
        top: 18%;
      }
    }
  }
  @include xxl-screen {

    &__screen {
      &__draw {
        left: 65%;
        top: 20%;
      }

      &__mail {
        left: 10%;
        top: 30%;
      }

      &__notes {
        left: 40%;
        top: 15%;
      }

      &__safari {
        left: 28%;
        top: 18%;
      }
    }
  }

  @include medium-screen {
    &__screen {
      &__draw {
        left: 55%;
        top: 50%;
      }

      &__mail {
        left: 10%;
        top: 20%;
      }

      &__notes {
        left: 50%;
        top: 15%;
      }

      &__safari {
        left: 15%;
        top: 50%;
      }
    }
  }
}
